import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConfirmationModalComponent } from './confirmation-modal.component';

export interface ConfirmationModalConfiguration {
  title: string;
  description: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  dismissible?: boolean;
  iconUrl?: string;
  iconColor?: string;
  resources?: { name: string; url: string }[];
  titleDirection?: 'row' | 'column' | 'column-centered';
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(public readonly modalController: ModalController) {}

  public async showConfirmation(config: ConfirmationModalConfiguration): Promise<boolean> {
    const modal = await this.modalController.create({
      component: ConfirmationModalComponent,
      cssClass: ['fu-modal'],
      componentProps: { configuration: config },
      backdropDismiss: false
    });

    await modal.present();

    const { role } = await modal.onWillDismiss();

    return role === 'confirm';
  }
}
