import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

export interface HrModalButton {
  text: string,
  data: HrModalState,
  fill: 'clear' | 'outline' | 'solid'
}

type HrModalState = 'connect' | 'disableOnce' | 'disable'

@Component({
  selector: 'lib-hr-monitor-modal',
  templateUrl: './hr-monitor-modal.component.html',
  styleUrls: ['./hr-monitor-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, TranslateModule]
})
export class HrMonitorModalComponent {
  @Input({ required: true })
  public title!: string;

  @Input({ required: true })
  public description!: string;

  @Input({ required: true })
  public buttons!: HrModalButton[]

  constructor(private modalController: ModalController) {}

  public static async openModal(
    modalController: ModalController,
    title: string,
    description: string,
    buttons: HrModalButton[]
  ): Promise<HrModalState> {
    const modal = await modalController.create({
      id: 'hr-monitor-modal',
      component: HrMonitorModalComponent,
      componentProps: { title, description, buttons },
      cssClass: ['fu-modal']
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();

    return data;
  }

  protected async closeModal(selection: HrModalState): Promise<void> {
    await this.modalController.dismiss(selection, null, 'hr-monitor-modal');
  }
}
