import { createActionGroup, props } from '@ngrx/store';
import { ActionError } from '@fitup-monorepo/core/lib/model/common-types';
import { PinboardFilter } from './pinboard-filter.reducer';

export const pinboardFilterActions = createActionGroup({
  source: 'Pinboard Filter',
  events: {
    ['Set Filter']: props<{ filter: PinboardFilter }>(),
    ['Set Filter Success']: props<{ filter: PinboardFilter }>(),
    ['Set Filter Failure']: props<ActionError>()
  }
});
