import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';
import { FilePickerPlugin } from '@capawesome/capacitor-file-picker';
import { CameraDirection, CameraPlugin, CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera';
import { CAMERA, FILE_PICKER } from '@fitup-monorepo/core/lib/capacitor-injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class ProfileImagePickerService {
  readonly #nonScalableTypes = ['video', 'gif'];
  readonly #desiredWidth = 1080;
  readonly #desiredHeight = 1080;

  constructor(
    private readonly platform: Platform,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(FILE_PICKER) private readonly filePicker: FilePickerPlugin,
    @Inject(CAMERA) private readonly camera: CameraPlugin
  ) {}

  public getPicture(): Promise<string> {
    return this.platform.is('cordova') ? this.#getPictureMobile() : this.#getPictureDesktop();
  }

  public async getPhoto(useFrontCamera: boolean): Promise<string> {
    const options: ImageOptions = {
      direction: useFrontCamera ? CameraDirection.Front : CameraDirection.Rear,
      correctOrientation: true,
      quality: 100,
      resultType: CameraResultType.DataUrl,
      width: this.#desiredWidth,
      height: this.#desiredHeight,
      source: CameraSource.Camera
    };
    const image = await this.camera.getPhoto(options);
    return image.dataUrl;
  }

  async #getPictureMobile(): Promise<string> {
    const result = await this.filePicker.pickImages({
      limit: 1,
      readData: true
    });
    const image = result.files[0].data;

    if (image) {
      const resizedImage = await this.#resizeBase64Image(image, this.#desiredWidth, this.#desiredHeight);
      return `data:image/jpeg;base64,${resizedImage}`;
    }
    return null;
  }

  async #resizeBase64Image(base64String: string, width: number, height: number): Promise<string> {
    const image = new Image();
    image.src = `data:image;base64,${base64String}`;

    await image.decode();

    const canvas = this.#getCanvasWithImageAndSize(image, width, height);
    return canvas.toDataURL().split(',')[1];
  }

  async #getPictureDesktop(): Promise<string> {
    return new Promise(resolve => {
      const input = this.document.createElement('input');
      input.type = 'file';
      input.accept = '.png, .jpg, .jpeg';

      input.onchange = async (event: Event): Promise<void> => {
        const target: HTMLInputElement = event.target as HTMLInputElement;
        const file = target.files && target.files.length ? target.files[0] : undefined;
        const outputImageType = file.name.toLowerCase().endsWith('png') ? 'image/png' : 'image/jpeg';

        if (file) {
          const resizedFile = (await this.#resizeImage(
            file,
            this.#desiredWidth,
            this.#desiredHeight,
            100,
            outputImageType,
            1
          )) as string;
          resolve(resizedFile);
        }
      };
      input.click();
    });
  }

  #resizeImage(
    file: Blob,
    desiredWidth: number,
    desiredHeight: number,
    quality: number,
    outputImageType: string,
    outputType: number
  ): Promise<string | Blob> {
    return new Promise((resolve, reject) => {
      if (
        (desiredWidth !== 0 || desiredHeight !== 0) &&
        !this.#nonScalableTypes.some(type => file.type.includes(type))
      ) {
        //BASE64_STRING required or
        //Scaling required
        //Base64 string needs to be jpeg formatted so even if no scaling is required we need to do the conversion
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e: ProgressEvent<FileReader>): void => {
          const img = new Image();
          img.src = e.target.result.toString();
          img.onload = (): void => {
            const canvas = this.#getCanvasWithImageAndSize(img, desiredWidth, desiredHeight);

            const ctx = canvas.getContext('2d');

            if (outputType === 0) {
              // FILE_URI required, resolve with a blob
              ctx.canvas.toBlob(
                blob => {
                  resolve(blob);
                },
                outputImageType,
                quality / 100
              );
            } else {
              resolve(ctx.canvas.toDataURL(outputImageType, quality / 100));
            }
          };
        };

        reader.onerror = (error): void => {
          reject(error);
        };
      } else {
        // No scaling required and FILE_URI required?
        // FILE_URI required, resolve with a blob (a file is a blob)
        resolve(file);
      }
    });
  }

  #getCanvasWithImageAndSize(image: HTMLImageElement, width: number, height: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if ((image.height > height || image.width > width) && (width !== 0 || height !== 0)) {
      if (image.height / height > image.width / width) {
        canvas.width = image.width / (image.height / height);
        canvas.height = height;
      } else {
        canvas.width = width;
        canvas.height = image.height / (image.width / width);
      }
    } else {
      canvas.width = image.width;
      canvas.height = image.height;
    }
    context.drawImage(image, 0, 0, canvas.width, canvas.height);
    return canvas;
  }
}
