import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { AuthServerProvider } from '../services/auth/auth-jwt.service';
import { PlatformService } from '../services/platform/platform.service';
import { BrowserService } from '../services/browser/browser.service';
import { LocalStorageService } from '../services/local-storage.service';

export const challengeIdToJoinKey = 'challengeIdToJoin';

export const joinChallengeGuard: CanActivateFn = async (route: ActivatedRouteSnapshot): Promise<UrlTree> => {
  const localStorageService = inject(LocalStorageService);
  const authServerProvider = inject(AuthServerProvider);
  const router = inject(Router);
  const platformService = inject(PlatformService);
  const browserService = inject(BrowserService);
  const challengeId = route.paramMap.get('challengeId');

  const storedChallengeId = localStorageService.retrieve(challengeIdToJoinKey);
  if (!platformService.isNative() && !storedChallengeId) {
    await browserService.openUrlInApp(`join/${challengeId}`, () => {
      localStorageService.store(challengeIdToJoinKey, +challengeId);
    });
  } else {
    await localStorageService.store(challengeIdToJoinKey, +challengeId);
  }

  if (!(await authServerProvider.isAuthenticated())) {
    return router.createUrlTree(['']);
  }

  return router.createUrlTree(['challenges', challengeId]);
};
