import { createReducer, on } from '@ngrx/store';
import { pinboardFilterActions } from './pinboard-filter.actions';

export interface PinboardFilter {
  category?: string;
  location?: string;
  color?: string;
}

export interface PinboardFilterState {
  filter: PinboardFilter;
}

export const initialState: PinboardFilterState = {
  filter: { category: '', color: '', location: '' }
};

export const pinboardFilterReducer = createReducer(
  initialState,
  on(pinboardFilterActions.setFilter, (state, { filter }): PinboardFilterState => ({ ...state, filter }))
);
