import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from '../local-storage.service';

export interface AppState {
  isTrackingActivity: boolean;
}

@Injectable({ providedIn: 'root' })
export class StateService {
  private readonly stateSubject = new BehaviorSubject<AppState>({
    isTrackingActivity: false
  });

  public readonly state$: Observable<AppState> = this.stateSubject.asObservable();

  constructor() {}

  public updateState(patch: Partial<AppState>): void {
    this.stateSubject.next({ ...this.stateSubject.value, ...patch });
  }

  public getState(): AppState {
    return this.stateSubject.value;
  }
}
