import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { of, switchMap, from } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { profileApiActions } from './profile.actions';
import { selectCustomer } from './profile.selector';
import { ProfileService } from '../../services/profile/profile.service';
import { LocalStorageService } from '../../services/local-storage.service';

@Injectable()
export class ProfileEffects {
  public loadProfileApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileApiActions.loadProfile),
      switchMap(() =>
        this.profileService.getProfileInformation().pipe(
          tap(profile => {
            console.log('Loaded profile', profile.customer.id, profile.customer.uuid);
            this.localStorageService.store(this.profileStorageKey, profile);
          }),
          map(profile => profileApiActions.loadProfileSuccess({ profile })),
          catchError(error => 
            from(this.localStorageService.retrieve(this.profileStorageKey)).pipe(
              switchMap(storedProfile => {
                console.log('Error. Loading profile from local storage', error, storedProfile);
                if (!!storedProfile) {
                  return of(
                    profileApiActions.loadProfileSuccess({
                      profile: storedProfile
                    })
                  );
                } else {
                  return of(
                    profileApiActions.loadProfileFailure({
                      error,
                      errorMessage: 'GENERIC_ERROR'
                    })
                  );
                }
              })
            )
          )
        )
      )
    )
  );

  public updateCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileApiActions.updateCustomer),
      switchMap(({ request }) =>
        this.profileService.updateCustomer(request).pipe(
          map(() => profileApiActions.updateCustomerSuccess()),
          catchError(error =>
            of(
              profileApiActions.updateCustomerFailure({
                error,
                errorMessage: 'ERROR.CUSTOMER_UPDATE'
              })
            )
          )
        )
      )
    )
  );

  public updateProfilePicture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileApiActions.uploadImage),
      concatLatestFrom(() => this.store.select(selectCustomer)),
      switchMap(([props, customer]) =>
        this.profileService.uploadImage(props.image, customer.id).pipe(
          map(response => profileApiActions.uploadImageSuccess({ image: response.image })),
          catchError(error =>
            of(
              profileApiActions.uploadImageFailure({
                errorMessage: 'ERROR.UPLOAD_IMAGE',
                error
              })
            )
          )
        )
      )
    )
  );

  public deleteProfilePicture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileApiActions.deleteImage),
      switchMap(() =>
        this.profileService.deleteImage().pipe(
          map(() => profileApiActions.deleteImageSuccess()),
          catchError(error =>
            of(
              profileApiActions.deleteImageFailure({
                errorMessage: 'ERROR.DELETE_IMAGE',
                error
              })
            )
          )
        )
      )
    )
  );

  public setAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileApiActions.setAvatar),
      switchMap(props =>
        this.profileService.setAvatar(props.avatarId).pipe(
          map(() => profileApiActions.setAvatarSuccess()),
          catchError(error =>
            of(
              profileApiActions.setAvatarFailure({
                errorMessage: 'ERROR.SET_AVATAR',
                error
              })
            )
          )
        )
      )
    )
  );

  // endpoints do not return updated information, so profile needs to be reloaded (can be optimised in endpoint)
  public reloadProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileApiActions.setAvatarSuccess, profileApiActions.updateCustomerSuccess),
      map(() => profileApiActions.loadProfile())
    )
  );

  private profileStorageKey = 'profile';

  constructor(
    private readonly actions$: Actions,
    private readonly profileService: ProfileService,
    private readonly localStorageService: LocalStorageService,
    private readonly store: Store
  ) {}
}
