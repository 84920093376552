import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthServerProvider } from '@fitup-monorepo/core/lib/services/auth/auth-jwt.service';

export const pinboardGuard: CanActivateFn = async () => {
  const authServerProvider = inject(AuthServerProvider);
  const router = inject(Router);

  return (await authServerProvider.isAuthenticated()) ? router.createUrlTree(['/tabs/notifications']) : true;
};
