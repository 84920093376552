import { Injectable } from '@angular/core';
import { AuthServerProvider } from '../auth/auth-jwt.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isObjectOfType } from '../../util/type-util';

export enum Permission {
  basic = 'basic',
  live = 'live',
  nutrition = 'nutrition',
  teamChallenge = 'team-challenge',
  adminDashboard = 'admin-dashboard',
  trainer = 'trainer',
  pinboard = 'pinboard',
  fitAtWorkReminder = 'fit-at-work-reminder',
  mediaCenter = 'media-center',
  waterIntake = 'water-intake',
  miniChallenge = 'mini-challenge',
  activityTracking = 'activity-tracking',
  publicChallenge = 'public-challenge',
  virtualCoach = 'virtual-coach',
  zpp = 'zpp',
  free = 'free',
  rewards = 'rewards'
}

export interface DecodedToken {
  sub: string;
  auth: string;
  permissions: Permission[];
  partnerId: number;
  exp: number;
}

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private decodedTokenSubject = new BehaviorSubject<DecodedToken | undefined>(undefined);
  private permissionsSubject = new BehaviorSubject<Set<Permission>>(new Set());
  private initialized = false;

  constructor(private readonly authServerProvider: AuthServerProvider) {}

  private initialize() {
    if (!this.initialized) {
      this.decodedTokenSubject
        .asObservable()
        .pipe(
          map((decodedToken: DecodedToken) => {
            if (!!decodedToken) {
              return new Set(decodedToken.permissions);
            } else {
              return new Set<Permission>();
            }
          })
        )
        .subscribe(this.permissionsSubject);

      this.authServerProvider
        .observeToken()
        .pipe(map(token => this.parseToken(token)))
        .subscribe(this.decodedTokenSubject);
      this.initialized = true;
    }
  }

  public observerPermissions(): Observable<Set<Permission>> {
    this.initialize();
    return this.permissionsSubject.asObservable();
  }

  public getAllPermissions(): Set<Permission> {
    this.initialize();
    return this.permissionsSubject.value;
  }

  public hasPermission(permission: Permission): boolean {
    this.initialize();
    return this.permissionsSubject.value.has(permission);
  }

  public isCorporateUser(): boolean {
    this.initialize();
    const partnerId = this.decodedTokenSubject.value?.partnerId;
    return !!partnerId && partnerId > 0;
  }

  private parseToken(token: string): DecodedToken | undefined {
    if (!token) {
      return undefined;
    }
    const decodedToken = Buffer.from(token.split('.')[1], 'base64').toString('utf-8');
    const parsedToken = JSON.parse(decodedToken);
    if (!this.isDecodedToken(parsedToken)) {
      return undefined;
    }
    return parsedToken;
  }

  public hasRole(role: string): boolean {
    this.initialize();
    return this.decodedTokenSubject.value?.auth.includes(role);
  }

  public hasAnyRole(roles: string[]): boolean {
    this.initialize();
    if (!!this.decodedTokenSubject.value?.auth) {
      for (const role of roles) {
        if (this.decodedTokenSubject.value?.auth.includes(role)) {
          return true;
        }
      }
    }
    return false;
  }

  private isDecodedToken(token: unknown): token is DecodedToken {
    return isObjectOfType(token, ['permissions', 'auth']);
  }
}
