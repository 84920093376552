import { InjectionToken } from '@angular/core';
import { ScreenOrientationPlugin } from '@capawesome/capacitor-screen-orientation';
import { FilePickerPlugin } from '@capawesome/capacitor-file-picker';
import { CameraPlugin } from '@capacitor/camera';
import { HapticsPlugin } from '@capacitor/haptics';
import { SharePlugin } from '@capacitor/share';
import { PushNotificationsPlugin } from '@capacitor/push-notifications';
import { NativeSettingsPlugin } from 'capacitor-native-settings';
import { DevicePlugin } from '@capacitor/device';
import { AppUpdatePlugin } from '@capawesome/capacitor-app-update';
import { KeepAwakePlugin } from '@capacitor-community/keep-awake';
import { BrowserPlugin } from '@capacitor/browser';
import { NativeAudio } from '@capacitor-community/native-audio';
import { AppPlugin } from '@capacitor/app';
import { FilesystemPlugin } from '@capacitor/filesystem';
import { PurchasesPlugin } from '@revenuecat/purchases-capacitor/dist/esm/definitions';
import { StatusBarPlugin } from '@capacitor/status-bar';
import { NavigationBarPlugin } from '@hugotomazi/capacitor-navigation-bar';
import { WizardService } from './services/wizard/wizard.service';
import { HealthPlugin } from 'capacitor-health';
import { BugfenderFacade } from '@bugfender/common';
import { PreferencesPlugin } from '@capacitor/preferences';

export const SCREEN_ORIENTATION = new InjectionToken<ScreenOrientationPlugin>('ScreenOrientation');
export const FILE_PICKER = new InjectionToken<FilePickerPlugin>('FilePicker');
export const CAMERA = new InjectionToken<CameraPlugin>('Camera');
export const HAPTICS = new InjectionToken<HapticsPlugin>('Haptics');
export const SHARE = new InjectionToken<SharePlugin>('SharePlugin');
export const APP_UPDATE = new InjectionToken<AppUpdatePlugin>('AppUpdate');
export const APP = new InjectionToken<AppPlugin>('App');
export const PUSH_NOTIFICATIONS = new InjectionToken<PushNotificationsPlugin>('PushNotifications');
export const NATIVE_SETTINGS = new InjectionToken<NativeSettingsPlugin>('NativeSettings');
export const HEALTH = new InjectionToken<HealthPlugin>('HealthPlugin');
export const DEVICE = new InjectionToken<DevicePlugin>('Device');
export const KEEP_AWAKE = new InjectionToken<KeepAwakePlugin>('KeepAwake');
export const BROWSER = new InjectionToken<BrowserPlugin>('Browser');
export const NATIVE_AUDIO = new InjectionToken<NativeAudio>('NativeAudio');
export const FILESYSTEM = new InjectionToken<FilesystemPlugin>('Filesystem');
export const PURCHASES = new InjectionToken<PurchasesPlugin>('PurchasesPlugin');
export const STATUS_BAR = new InjectionToken<StatusBarPlugin>('StatusBarPlugin');
export const NAVIGATION_BAR = new InjectionToken<NavigationBarPlugin>('NavigationBarPlugin');
export const WIZARD_SERVICE = new InjectionToken<WizardService>('WizardService');
export const BUGFENDER = new InjectionToken<BugfenderFacade>('Bugfender');
export const WINDOW = new InjectionToken<Window>('window');
export const PREFERENCES = new InjectionToken<PreferencesPlugin>('Preferences');
