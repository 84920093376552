import { Injectable } from '@angular/core';
import { firstValueFrom, interval } from 'rxjs';

/**
 * Service for storing data in local storage.
 * The service does not store null or undefined values. If you try to store null or undefined, the value will be removed
 * from the storage. The retrieve method will return null if the stored value is "undefined" (as a string) or not present.
 * The value is stored as a JSON string und JSON.stringify and JSON.parse. This means that the value must be serializable.
 */
@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  private storage: Storage;
  private prefix = 'jhi-';

  constructor() {
    this.storage = window.localStorage;
  }

  private getKey(key: string): string {
    return `${this.prefix}${key.toLowerCase()}`;
  }

  public async store(key: string, value: any): Promise<void> {
    await firstValueFrom(interval(Math.random() * 5));
    if (value === null || value === undefined) {
      console.error('Trying to store null or undefined value for key:', key);
      await this.clear(key);
      return;
    }
    const stringValue = JSON.stringify(value);
    this.storage.setItem(this.getKey(key), stringValue);
  }

  public async retrieve(key: string): Promise<any> {
    await firstValueFrom(interval(Math.random() * 5));
    const item = this.storage.getItem(this.getKey(key));
    if (!!item && item !== 'undefined') {
      return JSON.parse(item);
    }
    return null;
  }

  public async clear(key: string): Promise<void> {
    await firstValueFrom(interval(Math.random() * 5));
    this.storage.removeItem(this.getKey(key));
  }

  public async clearAll(): Promise<void> {
    await firstValueFrom(interval(Math.random() * 5));
    this.storage.clear();
  }

  public async has(key: string): Promise<boolean> {
    return (await this.retrieve(key)) !== null;
  }
}
