import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { AnimationController, IonicModule, ModalController } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { DurationPipe } from '@fitup-monorepo/core/lib/pipes/duration-pipe';
import { FitPointEarningSummary } from '@fitup-monorepo/core/lib/model/fit-point-earning-summary';
import { ShareActivityComponent, ShareActivityConfig } from '../share-activity/share-activity.component';
import { DividerComponent } from '../divider/divider.component';
import { RewardBoxComponent } from '../reward-box/reward-box.component';
import { LocalizedNumberPipe } from "@fitup-monorepo/core/lib/pipes/localized-number.pipe";

export interface ActivityDetails {
  duration?: number;
  calories?: number;
  heartRate?: number;
  shareActivityConfig?: ShareActivityConfig;
}

interface ListConfig {
  unit?: string;
  color: string;
  icon: string;
  label: string;
  value: string | number;
}

export interface PointConfig {
  pointSummary: FitPointEarningSummary;
  page: 'media-center' | 'virtual-coach' | 'activity';
  activityDetails: ActivityDetails;
}

@Component({
  selector: 'app-points',
  templateUrl: './points.page.html',
  styleUrls: ['./points.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [HeaderComponent, IonicModule, TranslateModule, RouterModule, DividerComponent, RewardBoxComponent, LocalizedNumberPipe],
  standalone: true
})
export class PointsPage implements AfterViewInit {
  @Input({ required: true })
  public pointConfig!: PointConfig;

  constructor(
    private readonly animationCtrl: AnimationController,
    private readonly modalController: ModalController,
    private readonly durationPipe: DurationPipe,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  public async ngAfterViewInit(): Promise<void> {
    await this.playAnimation();
  }

  protected async playAnimation(): Promise<void> {
    const listElements = this.document.querySelectorAll('.point-list');
    const listAnimations = this.getListConfig().map((_, index) =>
      this.animationCtrl
        .create('button')
        .addElement(listElements[index])
        .duration(1000)
        .delay((index + 1) * 100)
        .keyframes([
          { offset: 0, opacity: '0', transform: 'translateY(500px)' },
          { offset: 1, opacity: '1', transform: 'translateY(0)' }
        ])
    );

    const rewardBoxes = this.document.querySelectorAll('.reward-box-animation');
    const labelAnimations = Array(rewardBoxes.length)
      .fill(null)
      .map((_, id) =>
        this.animationCtrl
          .create('info')
          .addElement(rewardBoxes[id])
          .duration(1000)
          .delay(400)
          .keyframes([
            { offset: 0, opacity: '0', transform: 'translateY(500px)' },
            { offset: 1, opacity: '1', transform: 'translateY(0)' }
          ])
      );

    const parentAnimation = this.animationCtrl
      .create('parent')
      .iterations(1)
      .easing('linear')
      .direction('alternate')
      .addAnimation([...listAnimations, ...labelAnimations]);

    await parentAnimation.play();
  }

  protected async onCloseModal(): Promise<void> {
    await this.modalController.dismiss(null, null, 'point-modal');
  }

  protected getListConfig(): ListConfig[] {
    return [
      {
        icon: 'clock-stopwatch',
        color: 'var(--fu-color-primary)',
        label: 'POINTS.DURATION',
        value: this.durationPipe.transform(this.pointConfig.activityDetails?.duration, 'hh:mm:ss') ?? '-/-'
      },
      {
        icon: 'fire-solid',
        color: 'var(--fu-orange)',
        label: 'POINTS.CALORIES',
        value: this.pointConfig.activityDetails?.calories ?? '-/-',
        unit: 'kCal'
      },
      {
        icon: 'avg-heart-rate',
        color: 'var(--fu-red)',
        label: 'POINTS.AVG_HEART_RATE',
        value: this.pointConfig.activityDetails?.heartRate?.toString() ?? '-/-',
        unit: 'bpm'
      }
    ];
  }

  protected async openShareActivityModal(): Promise<void> {
    await this.onCloseModal();
    await ShareActivityComponent.openModal(this.modalController, this.pointConfig.activityDetails.shareActivityConfig);
  }
}
