import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { Store } from '@ngrx/store';
import { profileApiActions } from '../../state/profile/profile.actions';
import { PreferencesPlugin } from '@capacitor/preferences';
import { LocalStorageService } from '../local-storage.service';
import { PREFERENCES } from '../../capacitor-injection-tokens';

export interface AuthCredentials {
  username: string;
  password: string;
  rememberMe: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthServerProvider {
  private token: string | undefined;
  private tokenSubject = new ReplaySubject<string | null>(1);

  constructor(
    private readonly http: HttpClient,
    @Inject(PREFERENCES) private readonly preferences: PreferencesPlugin,
    private readonly localStorage: LocalStorageService,
    private readonly store: Store
  ) {
    this.init();
  }

  private async init(): Promise<void> {
    try {
      const result = await this.preferences.get({ key: 'authenticationToken' });
      if (result?.value) {
        this.token = result.value;
        console.log('Got token from preferences');
      }
    } catch (error) {
      console.error('error getting token from preferences, falling back to localstorage', error);
    }

    if (!this.token) {
      this.token = (await this.localStorage.retrieve('authenticationToken')) as string;
      console.log('Got token from localstorage');
      if (!!this.token) {
        await this.preferences.set({ key: 'authenticationToken', value: this.token });
        console.log('stored token in preferences');
      }
    }
    this.tokenSubject.next(this.token);
  }

  public observeToken(): Observable<string | null> {
    return this.tokenSubject.asObservable();
  }

  public async isAuthenticated(): Promise<boolean> {
    return !!(await firstValueFrom(this.tokenSubject));
  }

  public async login(credentials: AuthCredentials): Promise<void> {
    const data = {
      username: credentials.username,
      password: credentials.password,
      rememberMe: true
    };

    const resp = await firstValueFrom(
      this.http.post(`${ApiService.API_URL}/authenticate`, data, { observe: 'response' })
    );
    const bearerToken = resp.headers.get('Authorization');
    if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
      const jwt = bearerToken.slice(7, bearerToken.length);
      await this.storeAuthenticationToken(jwt);
      this.store.dispatch(profileApiActions.loadProfile());
    } else {
      throw new Error('No bearer token found in response');
    }
  }

  /**
   * only to be used by AuthInterceptor
   */
  public getToken(): string | undefined {
    return this.token;
  }

  public getUpdatedToken(): Observable<string> {
    return (
      this.http
        // eslint-disable-next-line @typescript-eslint/naming-convention
        .get<{ id_token: string }>(`${ApiService.API_URL}/authenticate`)
        .pipe(map(response => response.id_token))
    );
  }

  public async updateJWT(): Promise<void> {
    if (await this.isAuthenticated()) {
      console.log('updating JWT token');
      const newToken = await firstValueFrom(this.getUpdatedToken());
      await this.storeAuthenticationToken(newToken);
      this.jwtUpdatedAt = new Date();
      console.log('JWT token updated');
    } else {
      console.log('no JWT token to update');
      throw new Error('no JWT token to update');
    }
  }

  private jwtUpdatedAt: Date = new Date(0);

  private readonly maxTokenAgeMillis = 6 * 60 * 60 * 1000;

  /**
   * Updates the JWT token if it is older than 24 hours
   * @returns true if the token was updated or still valid, false if the token is invalid
   */
  public async updateJWTIfNecessary(): Promise<void> {
    const ageMillis = Date.now() - this.jwtUpdatedAt.getTime();
    console.log('Checking if JWT token needs to be updated. Last update at, ageMillis', this.jwtUpdatedAt, ageMillis);
    if (ageMillis > this.maxTokenAgeMillis) {
      try {
        await this.updateJWT();
      } catch (e) {
        console.error('Error updating JWT token', e);
      }
    }
  }

  public async loginWithToken(jwt: string): Promise<void> {
    if (jwt) {
      await this.storeAuthenticationToken(jwt);
      this.store.dispatch(profileApiActions.loadProfile());
    } else {
      return Promise.reject('auth-jwt-service Promise reject'); // Put appropriate error message here
    }
  }

  private async storeAuthenticationToken(jwt: string): Promise<void> {
    this.token = jwt;
    await this.localStorage.store('authenticationToken', jwt);
    await this.preferences.set({ key: 'authenticationToken', value: jwt });

    if (!!jwt) {
      this.tokenSubject.next(jwt);
    }
  }

  private async doLogout(deviceId: string, firebaseToken: string): Promise<void> {
    try {
      await firstValueFrom(
        this.http.post<void>(`${ApiService.API_URL}/logout`, {
          deviceId,
          token: firebaseToken
        })
      );
    } catch (e) {
      console.error('Error logging out', e);
    }
    await this.localStorage.clear('authenticationToken');
    await this.preferences.remove({ key: 'authenticationToken' });
    this.tokenSubject.next(null);
  }

  public logout(deviceId: string, firebaseToken: string): Observable<void> {
    return fromPromise(this.doLogout(deviceId, firebaseToken));
  }
}
