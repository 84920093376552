import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, interval, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BleDevice, ScanMode } from '@capacitor-community/bluetooth-le';
import { BleClient } from '@capacitor-community/bluetooth-le/dist/esm/bleClient';
import { LocalStorageService } from '../local-storage.service';
import { ModalController, Platform } from '@ionic/angular';
import { HrModalButton, HrMonitorModalComponent } from './hr-monitor-modal/hr-monitor-modal.component';

export interface HeartRateValue {
  time: number;
  heartRate: number;
  error?: string;
}

export interface HeartRateState {
  connectedDevice?: BleDevice;
  heartRate$?: Subject<HeartRateValue>;
  state: 'DISCONNECTED' | 'CONNECTING' | 'CONNECTED';
}

enum States {
  Start = 'Start',
  RequestingEnableHrm = 'RequestingEnableHrm',
  ShowingConnectionError = 'ShowingConnectionError',
  HrmDisabled = 'HrmDisabled',
  Connecting = 'Connecting',
  Connected = 'Connected',
  Disconnected = 'Disconnected'
}

enum Events {
  RequestEnableHrm = 'RequestEnableHrm',
  DisableHrm = 'DisableHrm',
  DisableHrmOnce = 'DisableHrmOnce',
  ConnectToDevice = 'ConnectToDevice',
  ConnectToDeviceSuccess = 'ConnectToDeviceSuccess',
  ConnectFailure = 'ConnectFailure',
  DisconnectDevice = 'DisconnectDevice'
}

interface Transition {
  start: States;
  event: Events;
  end: States;
  callback: () => Promise<void>;
}

function t(start: States, event: Events, end: States, callback: () => Promise<void>): Transition {
  return {
    start,
    event,
    end,
    callback
  };
}

@Injectable({
  providedIn: 'root'
})
export class HeartRateService {
  private readonly hrmEnabledKey = 'HRM_ENABLED';
  private readonly configuredDeviceKey = 'HRM_DEVICE';

  private readonly transitions = [
    t(States.Start, Events.RequestEnableHrm, States.RequestingEnableHrm, this.onRequestEnableHrm),
    t(States.Start, Events.DisableHrm, States.HrmDisabled, this.onDisableHrm),
    t(States.Start, Events.ConnectToDevice, States.Connecting, this.onConnectToDevice),
    t(States.Start, Events.DisableHrmOnce, States.Disconnected, this.onDisableHrmOnce),

    t(States.Disconnected, Events.DisableHrm, States.HrmDisabled, this.onDisableHrm),
    t(States.Disconnected, Events.RequestEnableHrm, States.RequestingEnableHrm, this.onRequestEnableHrm),
    t(States.Disconnected, Events.ConnectToDevice, States.Connecting, this.onConnectToDevice),
    t(States.Disconnected, Events.DisconnectDevice, States.Disconnected, this.onDisconnectDevice),

    t(States.HrmDisabled, Events.RequestEnableHrm, States.RequestingEnableHrm, this.onRequestEnableHrm),
    t(States.HrmDisabled, Events.DisconnectDevice, States.HrmDisabled, this.onDisconnectDevice),

    t(States.RequestingEnableHrm, Events.DisableHrm, States.HrmDisabled, this.onDisableHrm),
    t(States.RequestingEnableHrm, Events.DisableHrmOnce, States.Disconnected, this.onDisableHrmOnce),
    t(States.RequestingEnableHrm, Events.ConnectToDevice, States.Connecting, this.onConnectToDevice),

    t(States.Connecting, Events.ConnectToDeviceSuccess, States.Connected, () => Promise.resolve()),
    t(States.Connecting, Events.ConnectFailure, States.ShowingConnectionError, this.onShowConnectionError),

    t(States.Connected, Events.DisconnectDevice, States.Disconnected, this.onDisconnectDevice),

    t(States.ShowingConnectionError, Events.ConnectToDevice, States.Connecting, this.onConnectToDevice),
    t(States.ShowingConnectionError, Events.DisableHrm, States.HrmDisabled, this.onDisableHrm),
    t(States.ShowingConnectionError, Events.DisableHrmOnce, States.Disconnected, this.onDisableHrmOnce)
  ];
  private previousInternalState: States;
  private internalState: States = States.Start;

  private stateSubject = new BehaviorSubject<HeartRateState>({
    state: 'DISCONNECTED'
  });

  public state$: Observable<HeartRateState> = this.stateSubject.asObservable();

  private readonly HEART_RATE_SERVICE = '0000180d-0000-1000-8000-00805f9b34fb';
  private readonly HEART_RATE_MEASUREMENT_CHARACTERISTIC = '00002a37-0000-1000-8000-00805f9b34fb';
  private readonly destroy$ = new Subject<void>();

  private isInitialised = false;

  constructor(
    private readonly platform: Platform,
    private readonly localStorageService: LocalStorageService,
    private readonly modalController: ModalController
  ) {
    console.log(
      this.transitions
        .map(t => `    ${t.start} --> |${t.event}| ${t.end}`)
        .reduce((p, c) => `${p}\n${c}`, 'flowchart TD')
    );
  }

  private patchState(p: Partial<HeartRateState>): void {
    this.stateSubject.next({
      ...this.stateSubject.value,
      ...p
    });
  }

  private async dispatch(e: Events): Promise<void> {
    const allowedTransitions = this.transitions.filter(t => t.start === this.internalState).filter(t => t.event === e);

    if (allowedTransitions.length === 1) {
      const t = allowedTransitions[0];
      console.log('dispatch', e, t);
      this.previousInternalState = this.internalState;
      this.internalState = t.end;
      await t.callback.bind(this)();
    } else {
      console.error('Not allowed transition', this.internalState, e, allowedTransitions);
    }
  }

  private getState(): States {
    return this.internalState;
  }

  public async connectIfConfigured(): Promise<void> {
    switch (this.getState()) {
      case States.Start:
      case States.Disconnected:
        const hrmEnabled = await this.localStorageService.retrieve(this.hrmEnabledKey);
        const configuredDevice = await this.localStorageService.retrieve(this.configuredDeviceKey);
        if (hrmEnabled === true && !!configuredDevice) {
          await this.dispatch(Events.ConnectToDevice);
        } else if (hrmEnabled === false) {
          await this.dispatch(Events.DisableHrm);
        } else {
          await this.dispatch(Events.RequestEnableHrm);
        }
        break;
      case States.HrmDisabled:
        //disabled, do nothing.
        break;
      default:
        console.log('Illegal state to start', this.getState());
    }
  }

  public async toggleHeartRate(): Promise<void> {
    console.log('toggleHeartRate. current state', this.getState());

    switch (this.getState()) {
      case States.Disconnected:
        await this.dispatch(Events.RequestEnableHrm);
        break;
      case States.HrmDisabled:
        await this.dispatch(Events.RequestEnableHrm);
        break;
      case States.Connected:
        await this.dispatch(Events.DisconnectDevice);
        break;
      default:
        console.error('Illegal state for toggleHeartRate. Trying to recover', this.getState());
        await this.disconnectDevice();
        console.error('Illegal state for toggleHeartRate. Disconnected from device');
        await this.localStorageService.clear(this.hrmEnabledKey);
        await this.localStorageService.clear(this.configuredDeviceKey);
        console.error(
          'Illegal state for toggleHeartRate. Cleared config. Resetting to start state and dispatching RequestEnableHrm'
        );
        this.internalState = States.Start;
        await this.dispatch(Events.RequestEnableHrm);
    }
  }

  private async onRequestEnableHrm(): Promise<void> {
    console.log('State', this.getState());
    const showAllOptions = this.previousInternalState === States.Start;
    await this.showModal(
      'ACTIVITY.HR_MONITOR_MODAL.TITLE_CONNECT',
      'ACTIVITY.HR_MONITOR_MODAL.DESCRIPTION_CONNECT',
      showAllOptions
    );
  }

  private async showModal(
    titleKey: string,
    descriptionKey: string,
    showAllOptions: boolean,
    isErrorModal = false
  ): Promise<void> {
    const modalButtons: HrModalButton[] = showAllOptions
      ? [
          {
            text: `ACTIVITY.HR_MONITOR_MODAL.${isErrorModal ? 'BUTTON_CONNECT_AGAIN' : 'BUTTON_CONFIRM'}`,
            data: 'connect',
            fill: 'solid'
          },
          {
            text: 'ACTIVITY.HR_MONITOR_MODAL.BUTTON_CONTINUE',
            data: 'disableOnce',
            fill: 'outline'
          },
          {
            text: 'ACTIVITY.HR_MONITOR_MODAL.BUTTON_CANCEL',
            data: 'disable',
            fill: 'clear'
          }
        ]
      : [
          {
            text: 'ACTIVITY.HR_MONITOR_MODAL.BUTTON_CONFIRM',
            data: 'connect',
            fill: 'solid'
          },
          {
            text: 'ACTIVITY.HR_MONITOR_MODAL.BUTTON_KEEP_DISABLED',
            data: 'disable',
            fill: 'outline'
          }
        ];
    const selection = await HrMonitorModalComponent.openModal(
      this.modalController,
      titleKey,
      descriptionKey,
      modalButtons
    );
    switch (selection) {
      case 'connect':
        await this.dispatch(Events.ConnectToDevice);
        break;
      case 'disable':
        await this.dispatch(Events.DisableHrm);
        break;
      case 'disableOnce':
        await this.dispatch(Events.DisableHrmOnce);
        break;
      default:
    }
  }

  private async scanAndConnect(): Promise<void> {
    this.patchState({ state: 'CONNECTING' });

    await this.localStorageService.clear(this.configuredDeviceKey);

    await this.initBle();

    let device: BleDevice | undefined;
    try {
      device = await BleClient.requestDevice({
        services: [this.HEART_RATE_SERVICE],
        scanMode: ScanMode.SCAN_MODE_LOW_LATENCY
      });
    } catch (error) {
      device = undefined;
    }

    if (device) {
      await this.localStorageService.store(this.configuredDeviceKey, device);
      try {
        await this.connectAndMonitor(device);
        await this.dispatch(Events.ConnectToDeviceSuccess);
      } catch (error) {
        console.error('error connecting to scanned device', error);
        await this.dispatch(Events.ConnectFailure);
      }
    } else {
      await this.dispatch(Events.ConnectFailure);
    }
  }

  private async onDisableHrm(): Promise<void> {
    console.log('State', this.getState());
    await this.localStorageService.store(this.hrmEnabledKey, false);
    await this.localStorageService.clear(this.configuredDeviceKey);
    await this.disconnectDevice();
  }

  private async onDisableHrmOnce(): Promise<void> {
    console.log('State', this.getState());
    await this.disconnectDevice();
  }

  private async onConnectToDevice(): Promise<void> {
    console.log('State', this.getState());
    await this.localStorageService.store(this.hrmEnabledKey, true);
    const device: BleDevice = (await this.localStorageService.retrieve(this.configuredDeviceKey)) as BleDevice;

    if (device) {
      try {
        await this.connectPreferredDevice(device);
        await this.dispatch(Events.ConnectToDeviceSuccess);
      } catch (error) {
        console.error('Error connecting configured device', error);
        await this.dispatch(Events.ConnectFailure);
      }
    } else {
      await this.scanAndConnect();
    }
  }

  private async onShowConnectionError(): Promise<void> {
    console.log('State', this.getState());
    await this.localStorageService.clear(this.configuredDeviceKey);
    await this.showModal(
      'ACTIVITY.HR_MONITOR_MODAL.TITLE_ERROR',
      'ACTIVITY.HR_MONITOR_MODAL.DESCRIPTION_ERROR',
      true,
      true
    );
  }

  private async onDisconnectDevice(): Promise<void> {
    console.log('State', this.getState());
    await this.disconnectDevice();
  }

  private async connectPreferredDevice(configuredDevice: BleDevice): Promise<void> {
    this.patchState({ state: 'CONNECTING' });
    await this.initBle();

    let error: any | undefined = undefined;
    const foundDevice: Subject<BleDevice> = new Subject();
    console.log('starting scan');
    BleClient.requestLEScan(
      {
        services: [this.HEART_RATE_SERVICE],
        name: configuredDevice.name,
        allowDuplicates: false,
        scanMode: ScanMode.SCAN_MODE_LOW_LATENCY
      },
      result => {
        console.log('scan result', result);
        foundDevice.next(result.device);
      }
    ).catch(e => {
      console.error('Error scanning', e);
      error = e;
    });

    const device = await Promise.race([
      firstValueFrom(interval(3000).pipe(map<number, BleDevice>(_ => undefined))),
      firstValueFrom(foundDevice)
    ]);
    await BleClient.stopLEScan();

    if (error) {
      return Promise.reject(error);
    }

    if (!device) {
      return Promise.reject('PREFERRED_DEVICE_NOT_FOUND');
    }

    await this.connectAndMonitor(device);
  }

  public async disconnect(): Promise<void> {
    await this.dispatch(Events.DisconnectDevice);
  }

  private async disconnectDevice(): Promise<void> {
    const device = this.stateSubject.value.connectedDevice;
    if (device) {
      this.destroy$.next();
      const deviceId = device.deviceId;
      try {
        await BleClient.stopNotifications(
          deviceId,
          this.HEART_RATE_SERVICE,
          this.HEART_RATE_MEASUREMENT_CHARACTERISTIC
        );
      } catch (e) {
        console.error('Error stopping notifications', e);
      }

      try {
        await BleClient.disconnect(deviceId);
      } catch (e) {
        console.error('Error disconnecting', e);
      }
    }
    this.patchState({
      state: 'DISCONNECTED',
      connectedDevice: undefined,
      heartRate$: undefined
    });
  }

  private async initBle(): Promise<void> {
    if (!this.isInitialised) {
      await BleClient.initialize({ androidNeverForLocation: true });
      this.isInitialised = true;
    }
  }

  private onDisconnect(device: BleDevice): void {
    console.log('onDisconnect', device);
    if (
      this.internalState === States.Connected &&
      this.stateSubject.value.connectedDevice?.deviceId === device.deviceId
    ) {
      this.connectAndMonitor(device).catch(e => {
        console.error('Error reconnecting', e);
        this.stateSubject.value.heartRate$?.next({
          time: Date.now(),
          heartRate: -1,
          error: 'DISCONNECTED'
        });
        firstValueFrom(interval(2000)).then(() => this.onDisconnect(device));
      });
    }
  }

  private async connectAndMonitor(device: BleDevice): Promise<void> {
    const heartRate$ = new Subject<HeartRateValue>();

    if (this.platform.is('android')) {
      // https://github.com/capacitor-community/bluetooth-le?tab=readme-ov-file#connection-fails-on-android
      await BleClient.disconnect(device.deviceId);
    }

    await BleClient.connect(device.deviceId, _ => this.onDisconnect(device));

    await BleClient.startNotifications(
      device.deviceId,
      this.HEART_RATE_SERVICE,
      this.HEART_RATE_MEASUREMENT_CHARACTERISTIC,
      value => {
        const heartRate = this.parseHeartRate(value);
        console.log('current heart rate', heartRate);
        heartRate$.next({
          time: Date.now(),
          heartRate
        });
      }
    );

    this.patchState({
      connectedDevice: device,
      heartRate$: heartRate$,
      state: 'CONNECTED'
    });
  }

  private parseHeartRate(value: DataView): number {
    const flags = value.getUint8(0);
    const rate16Bits = flags & 0x1;
    let heartRate: number;
    if (rate16Bits > 0) {
      heartRate = value.getUint16(1, true);
    } else {
      heartRate = value.getUint8(1);
    }
    return heartRate;
  }
}
