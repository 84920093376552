import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { challengeActions } from './challenge.actions';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { ChallengeService } from '../services/challenge.service';
import { forkJoin, of, from } from 'rxjs';
import { LocalStorageService } from '@fitup-monorepo/core/lib/services/local-storage.service';
import { HttpStatusCode } from '@angular/common/http';
import { challengeIdToJoinKey } from '@fitup-monorepo/core/lib/guards/join-challenge.guard';
import { Permission, PermissionsService } from '@fitup-monorepo/core/lib/services/permissions/permissions.service';
import { ChallengeModalService } from '../services/challenge-modal.service';

@Injectable()
export class ChallengeEffects {
  public readonly loadChallenge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(challengeActions.loadChallenge),
      switchMap(({ challengeId }) =>
        this.challengeService.getChallenge(challengeId).pipe(
          map(challenge => challengeActions.loadChallengeSuccess({ challenge })),
          catchError(error => of(challengeActions.loadChallengeFailure({ error })))
        )
      )
    )
  );

  public readonly loadCommunityOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(challengeActions.loadCommunityOverview),
      exhaustMap(() =>
        this.challengeService.getCommunityChallengeOverview().pipe(
          map(communityOverview =>
            challengeActions.loadCommunityOverviewSuccess({
              overview: communityOverview
            })
          ),
          catchError(error => of(challengeActions.loadCommunityOverviewFailure({ error })))
        )
      )
    )
  );

  public readonly loadPartnerOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(challengeActions.loadPartnerOverview),
      exhaustMap(() =>
        this.challengeService.getPartnerChallengeOverview().pipe(
          map(partnerOverview =>
            challengeActions.loadPartnerOverviewSuccess({
              overview: partnerOverview
            })
          ),
          catchError(error => of(challengeActions.loadPartnerOverviewFailure({ error })))
        )
      )
    )
  );

  public readonly joinChallenge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(challengeActions.joinChallenge),
      switchMap(({ challengeId }) =>
        this.challengeService.joinCommunityChallenge(challengeId).pipe(
          map(challenge => challengeActions.joinChallengeSuccess({ challenge })),
          switchMap(action =>
            from(this.localStorageService.retrieve(challengeIdToJoinKey)).pipe(
              tap(challengeIdToJoin => {
                if (challengeIdToJoin === challengeId) {
                  this.localStorageService.clear(challengeIdToJoinKey);
                }
              }),
              map(() => action)
            )
          ),
          catchError(error => {
            if (!this.permissionsService.hasPermission(Permission.basic) && error.status === HttpStatusCode.Forbidden) {
              this.challengeModalService.showModalForFreeUser(error.status);
            }
            return of(
              challengeActions.joinChallengeFailure({
                error,
                errorMessage: 'ERROR.JOIN_CHALLENGE'
              })
            );
          })
        )
      )
    )
  );

  public readonly markCompletedChallengeShown$ = createEffect(() =>
    this.actions$.pipe(
      ofType(challengeActions.markCompletedChallengesShown),
      switchMap(({ challengeIds }) =>
        forkJoin(challengeIds.map(id => this.challengeService.markCompletedShown(id))).pipe(
          map(challenges =>
            challengeActions.markCompletedChallengesShownSuccess({
              challenges
            })
          ),
          catchError(error => of(challengeActions.markCompletedChallengesShownFailure({ error })))
        )
      )
    )
  );

  public readonly leaveChallenge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(challengeActions.leaveChallenge),
      switchMap(({ challengeId }) =>
        this.challengeService.leaveChallenge(challengeId).pipe(
          map(challenge => challengeActions.leaveChallengeSuccess({ challenge })),
          catchError(error => of(challengeActions.leaveChallengeFailure({ error })))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly challengeService: ChallengeService,
    private readonly localStorageService: LocalStorageService,
    private readonly permissionsService: PermissionsService,
    private readonly challengeModalService: ChallengeModalService
  ) {}
}
